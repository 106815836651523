import './src/assets/css/bootstrap.min.css';
import './src/assets/css/animate.min.css';
import './src/assets/css/boxicons.min.css';
import './src/assets/css/flaticon.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-modal-video/css/modal-video.min.css';
import 'react-18-image-lightbox/style.css';
import 'react-tabs/style/react-tabs.css';
// import '../../../../node_modules/aos/dist/aos.css';
import 'aos/dist/aos.css'; // Corrected path for aos.css
import 'swiper/css';
import 'swiper/css/bundle';
// import './i18n';
// gatsby-browser.js
import 'flag-icons/css/flag-icons.min.css';

// Global Style
import './src/assets/css/style.css';
import './src/assets/css/responsive.css';
import './src/assets/css/dark.css';


// import React from 'react';
// import { I18nextProvider } from 'react-i18next';
// import i18n from './src/i18n';
// import { useI18next } from 'gatsby-plugin-react-i18next';

// const WrapPageElement = ({ element, props }) => {
//     const { language } = useI18next();

//     React.useEffect(() => {
//         const lang = props.pageContext.language || 'en';
//         i18n.changeLanguage(lang);
//     }, [props.pageContext.language]);

//     return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
// };

// export const wrapPageElement = WrapPageElement;
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/i18n';

export const wrapPageElement = ({ element, props }) => {
    return (
        <I18nextProvider i18n={i18n}>
            {element}
        </I18nextProvider>
    );
};
// export const onClientEntry = () => {
//     const clarityScript = document.createElement("script");
//     clarityScript.src = "https://www.clarity.ms/tag/okmhvj9kw0";
//     clarityScript.defer = true;
//     document.head.appendChild(clarityScript);

//     const gtagScript = document.createElement("script");
//     gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-9F4FZ6ZXVX";
//     gtagScript.defer = true;
//     document.head.appendChild(gtagScript);
// };

